
import { defineComponent, PropType } from 'vue';
import { SavedFilter } from '@/typings';
import BasfSwitch from '@/components/common/BasfSwitch.vue';
import FiltersUtils from '@/scripts/filtersUtils';

export default defineComponent({
  name: 'SaveFilter',
  components: {
    BasfSwitch
  },
  props: {
    savedFilters: {
      type: Array as PropType<Array<SavedFilter>>,
      required: true
    },
    selectedConditions: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isSaving: false,
      name: null as null | string,
      show: true,
      error: null as null | string,
      alertActive: false
    };
  },
  computed: {},
  methods: {
    saveFilter() {
      // validations
      this.error = null;
      const regEx = /^[0-9a-zA-Z ]+$/;
      if (this.name === null || !regEx.test(this.name)) {
        this.error = 'Please enter a valid name. Only alphanumeric characters allowed.';
        return;
      }
      if (this.savedFilters.find((f) => f.name.toUpperCase() == this.name!.toUpperCase())) {
        this.error = 'You already have a filter saved with this name.';
        return;
      }

      // all validations passed

      this.isSaving = true;

      const prepObj = {
        filterId: null,
        name: this.name,
        conditions: FiltersUtils.createSaveObject(this.selectedConditions)
      };

      this.$store
        .dispatch('filters/ADD_FILTER', {
          data: prepObj,
          alert: this.alertActive
        })
        .then((response) => {
          if (response) {
            this.$toast.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'The filter has been saved successfully.',
              life: 3000
            });
            this.$emit('applySavedFilter', response.filterId);
            this.$emit('close');
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'The item could not be saved.',
              life: 3000
            });
            this.isSaving = false;
          }
        });
    }
  },
  watch: {
    show() {
      this.$emit('close');
    }
  }
});
