
import { defineComponent } from 'vue';
import RowDetailTimeline from '@/components/main/RowDetailTimeline.vue';
import RowDetailInfo from '@/components/main/RowDetailInfo.vue';
import Spinner from '@/components/common/BasfSpinner.vue';

export default defineComponent({
  name: 'RowDetail',
  props: {
    row: {
      type: String,
      required: true
    },
    patchTop: {
      type: Number,
      required: true
    }
  },
  components: {
    RowDetailTimeline,
    RowDetailInfo,
    Spinner
  },
  data() {
    return {
      dataLoaded: false,
      timelineLoaded: false,
      data: {
        timeline: {},
        masterData: null
      }
    };
  },
  methods: {
    fetchData() {
      this.dataLoaded = false;
      this.timelineLoaded = false;
      this.$store.dispatch('GET_DETAIL_MASTER_DATA', this.row).then((resp) => {
        this.data.masterData = resp;
        this.dataLoaded = true;
      });

      this.$store.dispatch('GET_DETAIL_TIMELINE', this.row).then((resp) => {
        this.data.timeline = resp;
        this.dataLoaded = true;
        this.timelineLoaded = true;
      });
    }
  },
  watch: {
    row() {
      this.fetchData();
      //this.timeline.carrierStatus = this.row.status;
    }
  },
  mounted() {
    this.fetchData();
    //this.timeline.carrierStatus = this.row.status;
  }
});
