
import Checkbox from 'primevue/checkbox';
import ProgressSpinner from 'primevue/progressspinner';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { defineComponent, PropType } from 'vue';
import { TableColumn } from '@/typings';
import { tableColumnsHeaders } from '@/typings/enums';
import { Container, Draggable } from 'vue-dndrop';

export default defineComponent({
  name: 'ColumnsDialog',
  components: { Dialog, Button, ProgressSpinner, Checkbox, Container, Draggable },
  props: {
    columns: {
      type: Array as PropType<Array<TableColumn>>,
      default: () => []
    }
  },
  data() {
    return {
      localColumns: JSON.parse(JSON.stringify(this.columns)) as TableColumn[],
      isSaving: false,
      show: true,
      columnsHeaders: tableColumnsHeaders
    };
  },
  computed: {
    columnsSortedByHeader(): TableColumn[] {
      return this.localColumns.slice().sort((a, b) => (this.columnsHeaders[a.field] > this.columnsHeaders[b.field] ? 1 : -1));
    },
    selectedColumns(): TableColumn[] {
      return this.localColumns
        .slice()
        .filter((column) => column.visible)
        .sort((a, b) => (a.order > b.order ? 1 : -1));
    },
    hasChanges(): boolean {
      return this.localColumns.some((column) => {
        const originalColumn = this.columns.find((c) => c.field === column.field) as TableColumn;

        return originalColumn.order !== column.order || originalColumn.visible !== column.visible;
      });
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    resetColumns() {
      this.$emit('reset');
      this.closeDialog();
    },
    getGhostParent() {
      return document.getElementsByClassName('p-dialog-mask')[0];
    },
    save() {
      this.isSaving = true;
      const saveObj = this.localColumns.map((m) => {
        return {
          field: m.field,
          order: m.order,
          visible: m.visible,
          width: m.width
        };
      });

      this.$emit('saveTableSettings', saveObj);
    },
    updateList(useIndex = false) {
      this.selectedColumns.forEach((item, index) => {
        if (useIndex) {
          item.order = index + 1;
        } else {
          const newSort = index + 1;
          if (item.order !== newSort) {
            item.order = newSort;
          }
        }
      });
    },
    updateListSort(result) {
      // will put the moved element in an intermediate position of the current order, so upldate list function will re-enumerate properly
      let valToAdd = 0;
      if (result.removedIndex < result.addedIndex) {
        // moved item up
        valToAdd = 1.5;
      } else if (result.removedIndex > result.addedIndex) {
        // moved item down
        valToAdd = 0.5;
      }
      this.selectedColumns[result.removedIndex].order = result.addedIndex + valToAdd;
    },
    isLastColumnVisible(column) {
      const visibleColumns = this.localColumns.filter((c) => c.visible);
      return visibleColumns.length === 1 && column.field === visibleColumns[0].field;
    }
  },
  watch: {
    selectedColumns(newVal, oldVal) {
      const itemAddedOrRemoved = oldVal.length > 0 && newVal.length !== oldVal.length;

      // Added item will be the last in the sortable list
      if (oldVal.length > 0 && newVal.length > oldVal.length) {
        const addedItem = newVal.find((column) => !oldVal.map((cc) => cc.field).includes(column.field));
        addedItem.order = newVal.length;
      }

      this.updateList(itemAddedOrRemoved);
    },
    show() {
      this.$emit('close');
    }
  }
});
