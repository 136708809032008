import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fe14fca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "filterListItem__list"
}
const _hoisted_2 = { class: "filterListItem__list__actions" }
const _hoisted_3 = {
  key: 1,
  class: "filterListItem__rename"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContextMenu = _resolveComponent("ContextMenu")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["filterListItem", {
      'filterListItem--selected': _ctx.filter.selected
    }])
  }, [
    (!_ctx.renaming)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(_ctx.filter.name), 1),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              role: "button",
              class: "context b-icon dots",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleContext && _ctx.handleContext(...args)), ["stop"]))
            }),
            _createVNode(_component_ContextMenu, {
              model: _ctx.filtersMenu,
              ref: "filtersContextMenu"
            }, null, 8, ["model"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("form", null, [
            _createVNode(_component_InputText, {
              ref: "nameField",
              type: "text",
              modelValue: _ctx.newName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newName) = $event)),
              class: "full p-inputtext-sm",
              maxlength: "25"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_Button, {
              icon: "b-icon close",
              class: "p-button-text",
              type: "button",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.renaming = false), ["stop"]))
            }),
            _createVNode(_component_Button, {
              icon: "b-icon check",
              class: "p-button-text",
              type: "submit",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.renameFilter()))
            })
          ])
        ]))
  ], 2))
}